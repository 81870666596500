
import {mapGetters} from 'vuex';
import {rzSelect} from 'razlet-ui';

export default {
  name: 'change-airport',
  components: {
    rzSelect,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters(['about', 'sitesList']),
    domainList() {
      return this.sitesList.map(item => Object.assign(item, {label: item.name}));
    },
    city() {
      return {
        code: this.about.code,
        label: this.about.title,
      };
    },
  },
  methods: {
    selectCity: function (data) {
      if (this.about.code !== data.code && typeof window !== 'undefined') {
        window.location.href = data.url;
      }
    },
  },
};
