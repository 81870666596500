
import {mapGetters} from 'vuex';

export default {
  name: 'main-contacts',
  data() {
    return {
      razletPhone: '+7 950 407-42-17',
      razletWaPhone: '+7 953 588-87-00',
    };
  },
  computed: {
    ...mapGetters(['about']),
  },
  methods: {
    getPhoneLink(phone) {
      return phone ? phone.replace(/ /g, '').replace(/-/g, '').replace(/[()]/g, '') : '';
    },
  },
};
